<template>
<div style="max-width: 800px">

  <div class="card-box">
    <h4>Block Lists</h4>
    <p>Download the block lists for all the various countries</p>
    <CButton @click="loadPath('files/block-lists')" color="info" class="card-box-button">
      Block Lists
    </CButton>
  </div>

  <div class="card-box">
    <h4>Allow Lists</h4>
    <p>Download the allow lists for all the various area codes</p>
    <CButton @click="loadPath('files/allow-lists')" color="info" class="card-box-button">
      Allow Lists
    </CButton>
  </div>

  <div class="card-box">
    <h4>Documents</h4>
    <p>View all the various documents the Command Center publishes</p>
    <CButton @click="loadPath('files/documents')" color="info" class="card-box-button">
      Documents
    </CButton>
  </div>

</div>
</template>

<script>
export default {
  name: 'Block-Allow-Lists',
  methods: {
    //--------------------------------------------------------------------------
    loadPath(path) {
      this.$router.push({
        path: path
      });
    },
  }
}
</script>
